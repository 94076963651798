import React from "react"
import Fade from "react-reveal/Fade"
import { Tabs } from 'antd'
import ContentOne from "./contentOne"
import { Parallax } from 'react-scroll-parallax'
import ContentTwo from "./contentTwo"
import ContentThree from "./contentThree"
import ContentFour from "./contentFour"

const About = () => {
  return (
    <div className="secion" id="about">
      <div className="container">
        <div className="about-section">
          <div className="content">
            <div className="me">
              <Fade bottom cascade>
                <h1>About Me</h1>
              </Fade>
              <p>
                Hi, I’m John Lodan Tojot, I graduated last 2017 in Technological Institute of the Philippines with a Bachelor of Science in Information Technology.
              </p>
              <p>
                Passionate software developer with a <b><u>7 years of experience</u></b> in web and mobile application with backend, Dedicated to building and optimizing user-friendly web and mobile using new technologies that make a positive impact to the project, I am Proficient in <b>Frontend(ReactJS/React-Native)</b>, <b>Backend(ExpressJS, MongoDB)</b>, I Have a strong critical thinking and problem solving skill that helps and give solutions to the client requirements.
              </p>
              <p>My hobbies and interests are listening to music while coding or doing my jobs, it helps to boost my critical thinking and problem solving. Also I like playing musical instruments especially guitar and drums. And, love motorcycle rides going one place to another just like touring. </p>
            </div>

            <div className="professional"  >
              <h1>Professional timelines:</h1>
              <div className="content">
                <Tabs
                  tabPosition={'left'}
                  items={[
                    {
                      label: <div className="tab-item">
                        <h1>Nov 2020 - Dec 2024</h1>
                        <p>Software Developer / Contractual</p>
                      </div>,
                      key: 1,
                      children: <ContentOne />,
                    },
                    {
                      label: <div className="tab-item">
                        <h1>Oct 2019 - Nov 2020</h1>
                        <p>Software Engineer</p>
                      </div>,
                      key: 2,
                      children: <ContentTwo />,
                    },
                    {
                      label: <div className="tab-item">
                        <h1>Jun 2017 - Sept 2019</h1>
                        <p>Frontend Engineer</p>
                      </div>,
                      key: 3,
                      children: <ContentThree />,
                    },
                    {
                      label: <div className="tab-item">
                        <h1>Nov 2016 - Feb 2017</h1>
                        <p>Technical Support Intern</p>
                      </div>,
                      key: 4,
                      children: <ContentFour />,
                    }
                  ]}
                />
              </div>
            </div>


            <div className="collab">
              <h1>Collaborated with an awesome companies:</h1>
              <div className="content">
                <div className="item">
                  <div className="img-wrapper">
                    <img src={require('../../images/company/bagl.png')} alt='bagl' />
                  </div>
                  <h1>Bugden Allen Graham Lawyers</h1>
                </div>
                <div className="item">
                  <div className="img-wrapper">
                    <img src={require('../../images/company/cwd.png')} alt='cwd' />
                  </div>
                  <h1>Cloudwalk Digital Inc.</h1>
                </div>
                <div className="item">
                  <div className="img-wrapper">
                    <img src={require('../../images/company/urc.png')} alt='urc' />
                  </div>
                  <h1>Universal Robina Corporation</h1>
                </div>
                <div className="item">
                  <div className="img-wrapper">
                    <img src={require('../../images/company/acudeen.png')} alt='acu' />
                  </div>
                  <h1>Acudeen Technologies Inc.</h1>
                </div>
                <div className="item">
                  <div className="img-wrapper">
                    <img src={require('../../images/company/twintool.png')} alt='twintool' />
                  </div>
                  <h1>Twintool Solution Enterprise</h1>
                </div>
                <div className="item">
                  <div className="img-wrapper">
                    <img src={require('../../images/company/cpmi.png')} alt='cpmi' />
                  </div>
                  <h1>Century Properties Management Inc.</h1>
                </div>
                <div className="item">
                  <div className="img-wrapper">
                    <img src={require('../../images/company/chatmatrix.png')} alt='simple' />
                  </div>
                  <h1>Chatmatrix</h1>
                </div>
                <div className="item">
                  <div className="img-wrapper">
                    <img src={require('../../images/company/simple.png')} alt='simple' />
                  </div>
                  <h1>Simple.IO</h1>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  )
}

export default About
