import levy from './images/projects/levycollect/1.png'
import residential from './images/projects/residential/1.png'
import inventory from './images/projects/inventory/1.png'
import exports1 from './images/projects/exports/1.png'
import simplePdf from './images/projects/simplePdf/1.png'
import lam from './images/projects/lam/1.png'
import hris from './images/projects/hris/1.png'
import deliber from './images/projects/deliber/1.png'
import hl7 from './images/projects/hl7/1.png'
import dental from './images/projects/dental/1.png'
import promis from './images/projects/promis/1.png'
import acudeen from './images/projects/acudeen/1.png'
import weguitar from './images/projects/weguitar/1.png'
import pentestkit from './images/projects/pentestkit/1.png'
import ecomm2 from './images/projects/ecomm/1.png'

const data = {
  name: "John Lodan Tojot",
  contactEmail: "johnlodantojot@gmail.com",
  projects: [
    {
      title: "Property Management Information System (Real Estate)",
      description: "Promis is an Accounting System which generates billing and reports for each property owner inside the premises.",
      imageSrc: promis,
      url: "",
      tech: ['React JS', 'Next Js', 'Javascript', 'CSS', 'SASS', 'HTML', 'Bootstrap', 'ExpressJS', 'NodeJs', 'MongoDB'],
      platform: ['Web'],
      role: ['Frontend', 'Backend'],
      images: [
        require('../src/images/projects/promis/1.png'),
        require('../src/images/projects/promis/2.png'),
        require('../src/images/projects/promis/3.png'),
        require('../src/images/projects/promis/4.png'),
        require('../src/images/projects/promis/5.png'),
        require('../src/images/projects/promis/6.png'),
        require('../src/images/projects/promis/7.png'),
        require('../src/images/projects/promis/8.png'),
        require('../src/images/projects/promis/9.png'),
        require('../src/images/projects/promis/10.png'),
      ],
      references: [
        'https://ep.aguafuente.net',
        'https://wp.aguafuente.net',
        'https://wpph.aguafuente.net',
        'https://mcc.aguafuente.net',
        'https://wtr.aguafuente.net'
      ]
    },

    {
      title: "LevyCollect Web App System (Strata)",
      description: "Developed a system for a Law Firm which provides a debt recovery for outstanding strata levies, the technology we are using are VueJs and C#.net core.",
      imageSrc: levy,
      url: "",
      tech: ['Vue JS', 'Javascript', 'CSS', 'SASS', 'HTML', 'Bootstrap', 'C#', 'MSSQL'],
      platform: ['Web'],
      role: ['Frontend', 'Backend'],
      images: [
        require('../src/images/projects/levycollect/1.png'),
        require('../src/images/projects/levycollect/2.png'),
        require('../src/images/projects/levycollect/3.png'),
      ],
      references: [
        // 'https://bagl.com.au',
        'https://levycollect.bagl.com.au'
      ]
    },
    {
      title: "Chatbot Ordering Management System in Messenger",
      description: "Chatbot Order Management System using your messenger through mobile and web.",
      imageSrc: ecomm2,
      url: "",
      tech: ['React JS', 'Redux', 'Javascript', 'PHP', 'CSS', 'SASS', 'HTML', 'ANTD', 'Laravel', 'MYSQL', 'Paymongo Payment'],
      platform: ['Web', 'Mobile'],
      role: ['Frontend', 'Backend'],
      images: [
        require('../src/images/projects/ecomm/1.png'),
        require('../src/images/projects/ecomm/2.png'),
        require('../src/images/projects/ecomm/3.png'),
        require('../src/images/projects/ecomm/4.png'),
        require('../src/images/projects/ecomm/5.png'),
        require('../src/images/projects/ecomm/6.png'),
        require('../src/images/projects/ecomm/7.png'),
        require('../src/images/projects/ecomm/8.png'),
      ],
      references: [
        'https://chatmatrix.com'
      ]
    },
    {
      title: "Residential Management Web and App System (Real Estate)",
      description: "A Residential Management System with a mobile application used to access available services and online payments, view announcements, billings, etc.",
      imageSrc: residential,
      url: require('./images/projects/residential/1.png'),
      tech: ['React JS', 'React Native', 'Redux', 'Javascript', 'CSS', 'SASS', 'HTML', 'ANTD', 'Laravel', 'MYSQL', 'DragonPay Payment', 'AQWIRE Payment'],
      platform: ['Web', 'Mobile'],
      role: ['Frontend', 'Backend'],
      images: [
        require('../src/images/projects/residential/1.png'),
        require('../src/images/projects/residential/2.png'),
        require('../src/images/projects/residential/3.png'),
        require('../src/images/projects/residential/4.png'),
        require('../src/images/projects/residential/5.png'),
        require('../src/images/projects/residential/6.png'),
        require('../src/images/projects/residential/7.png'),
        require('../src/images/projects/residential/8.png'),
        require('../src/images/projects/residential/9.png'),
      ],
      references: [
        'https://azure.centurycommunities.com.ph',
        'https://acqua.centurycommunities.com.ph',
        'https://spire.centurycommunities.com.ph',
        'https://trumptowerph.centurycommunities.com.ph',
        'https://centuria.centurycommunities.com.ph',
        'https://kb.centurycommunities.com.ph',
        'https://milano.centurycommunities.com.ph',
        'https://apps.apple.com/hr/app/residence-app-solution-portal/id1587930792?platform=iphone',
        'https://play.google.com/store/apps/details?id=com.residentsmobile'
      ]
    },
    {
      title: "Residential Inventory Sales Web and App System (Real Estate)",
      description: "A Residential Management System with a mobile application used by sellers to reserve a condo or property for their buyers",
      imageSrc: inventory,
      url: "",
      tech: ['React JS', 'React Native', 'Redux', 'Javascript', 'CSS', 'SASS', 'HTML', 'ANTD', 'Laravel', 'MYSQL'],
      platform: ['Web', 'Mobile'],
      role: ['Frontend', 'Backend'],
      images: [
        require('../src/images/projects/inventory/1.png'),
        require('../src/images/projects/inventory/2.png'),
        require('../src/images/projects/inventory/3.png'),
        require('../src/images/projects/inventory/4.png'),
        require('../src/images/projects/inventory/5.png'),
        require('../src/images/projects/inventory/6.png'),
        require('../src/images/projects/inventory/7.png'),
        require('../src/images/projects/inventory/8.png'),
        require('../src/images/projects/inventory/9.png'),
        require('../src/images/projects/inventory/10.png'),
      ],
      references: [
        'https://primeworldland.estatecommunities.net',
      ]
    },
    {
      title: "Human Resource Management Web and App System",
      description: "A Management System with a mobile application used to access available services, view announcements, personal data, pay slips, etc.",
      imageSrc: hris,
      url: "",
      tech: ['React JS', 'React Native', 'Redux', 'Javascript', 'CSS', 'SASS', 'HTML', 'ANTD', 'Python', 'Node', 'MongoDB'],
      platform: ['Web', 'Mobile'],
      role: ['Frontend', 'Backend'],
      images: [
        require('../src/images/projects/hris/1.png'),
        require('../src/images/projects/hris/2.png'),
        require('../src/images/projects/hris/3.png'),
        require('../src/images/projects/hris/4.png'),
        require('../src/images/projects/hris/5.png'),
        require('../src/images/projects/hris/6.png'),
        require('../src/images/projects/hris/7.png'),
      ]
    },
    {
      title: "PDF, Video and Audio Annotator",
      description: "Video and PDF Annotations App Online using ReactJS/C#, Can Add Texts, Shapes, Images and comments to an uploaded files in the system.",
      imageSrc: simplePdf,
      url: "",
      tech: ['React JS', 'Javascript', 'CSS', 'SASS', 'HTML', 'ANTD', 'C#'],
      platform: ['Web'],
      role: ['Frontend'],
      images: [
        require('../src/images/projects/simplePdf/1.png'),
        require('../src/images/projects/simplePdf/2.png'),
        require('../src/images/projects/simplePdf/3.png'),
        require('../src/images/projects/simplePdf/4.png'),
      ]
    },
    {
      title: "Epub Template Editor",
      description: "Is a Template Editor generated by Adobe InDesign Application. Which the user can upload the template into web and they can edit the details.",
      imageSrc: lam,
      url: "",
      tech: ['React JS', 'Redux', 'Javascript', 'CSS', 'SASS', 'HTML', 'ANTD', 'Material UI', 'C#', 'MYSQL'],
      platform: ['Web'],
      role: ['Frontend'],
      images: [
        require('../src/images/projects/lam/1.png'),
        require('../src/images/projects/lam/2.png'),
        require('../src/images/projects/lam/3.png'),
        require('../src/images/projects/lam/4.png'),
        require('../src/images/projects/lam/5.png'),
        require('../src/images/projects/lam/6.png'),
      ]
    },

    {
      title: "Universal Robina Corporation",
      description: "Order Management System and Content Management System.",
      imageSrc: exports1,
      url: "",
      tech: ['React JS', 'Redux', 'Javascript', 'CSS', 'SASS', 'HTML', 'ANTD', 'Laravel', 'MYSQL'],
      platform: ['Web'],
      role: ['Frontend'],
      images: [
        require('../src/images/projects/exports/1.png'),
        require('../src/images/projects/exports/2.png'),
        require('../src/images/projects/exports/3.png'),
        require('../src/images/projects/exports/4.png'),
        require('../src/images/projects/exports/5.png'),
        require('../src/images/projects/exports/6.png'),
        require('../src/images/projects/exports/7.png'),
        require('../src/images/projects/exports/8.png'),
        require('../src/images/projects/exports/9.png'),
        require('../src/images/projects/exports/10.png'),
        require('../src/images/projects/exports/11.png'),
        require('../src/images/projects/exports/12.png'),
      ],
      references: ['https://urcglobalexports.com.ph']
    },
    // {
    //   title: "Deliber (Deliver) App",
    //   description: "Order Management System for food industry using your mobile devices.",
    //   imageSrc: deliber,
    //   url: "",
    //   tech: ['Flutter', 'Dart', 'Javascript', 'CSS', 'SASS', 'HTML', 'Laravel', 'MYSQL'],
    //   platform: ['Web', 'Mobile'],
    //   role: ['Backend'],

    //   images: [
    //     require('../src/images/projects/deliber/1.png'),
    //   ]
    // },
    // {
    //   title: "Medical HL7 Parser",
    //   description: "Cron Job for Medical HL7 file, A Medical Program that are used for scanning HL7 File changes and push it into server.",
    //   imageSrc: hl7,
    //   url: "",
    //   tech: ['Electron JS', 'Javascript', 'CSS', 'SASS', 'HTML', 'Laravel', 'MYSQL'],
    //   platform: ['Desktop']
    // },
    {
      title: "Dental Management System",
      description: "A Dental application which where you can reserve an appointment, view your personal records and images.",
      imageSrc: dental,
      url: "",
      tech: ['React Native', 'React Native Paper', 'Redux', 'Javascript', 'CSS', 'SASS', 'HTML', 'Laravel', 'MYSQL'],
      platform: ['Mobile', 'Tablet'],
      role: ['Frontend'],
      images: [
        require('../src/images/projects/dental/1.png'),
      ]
    },
    {
      title: "Acudeen Technologies Inc.",
      description: "A Marketplace of invoices in a fintech company.",
      imageSrc: acudeen,
      url: "",
      tech: ['React JS', 'Redux', 'Javascript', 'CSS', 'SASS', 'HTML', 'Bootstrap', 'Python', 'Node', 'MYSQL'],
      platform: ['Web'],
      role: ['Frontend'],
      images: [
        require('../src/images/projects/acudeen/1.png'),
      ],
      references: ['https://acudeen.com', 'https://kyopay.com']
    },
    {
      title: "WeGuitar Mobile (Self Project) (50K+ Downloads)",
      description: "My self-project downloadable via google store and have a current 50k downloads, it has 700+ songs with a lyrics and chords.",
      imageSrc: weguitar,
      url: "",
      tech: ['React Native', 'Javascript', 'CSS', 'SASS', 'Native Base', 'Laravel', 'MySQL'],
      platform: ['Mobile'],
      role: ['Frontend', 'Backend'],
      images: [
        require('../src/images/projects/weguitar/1.png'),
        require('../src/images/projects/weguitar/2.png'),
        require('../src/images/projects/weguitar/3.png'),
        require('../src/images/projects/weguitar/4.png')
      ],
      references: [
        'https://play.google.com/store/apps/developer?id=The+Joloto+Project',
        'https://play.google.com/store/apps/details?id=com.jlt.weguitar'
      ]
    },
    {
      title: "PentestKit Mobile (Self Project) (50K+ Downloads)",
      description: "My self-project downloadable via google store and have a current (50k+ DOWNLOADS), this tool is good for Bug Bounty Player, Penetration Testers and who wants to learn security holes and vulnerability. Features: Browser, WhoIs, Hackbar, Finder, Google Dork, Web Crawler, Phone Number Crawler, Denial of Service, Create Persistent Shells and Proxy.",
      imageSrc: pentestkit,
      url: "",
      tech: ['React Native', 'Javascript', 'CSS', 'SASS', 'Native Base'],
      platform: ['Mobile'],
      role: ['Frontend', 'Backend'],
      images: [
        require('../src/images/projects/pentestkit/1.png'),
        require('../src/images/projects/pentestkit/2.png'),
        require('../src/images/projects/pentestkit/3.png'),
        require('../src/images/projects/pentestkit/4.png'),
        require('../src/images/projects/pentestkit/5.png'),
        require('../src/images/projects/pentestkit/6.png'),
        require('../src/images/projects/pentestkit/7.png'),
        require('../src/images/projects/pentestkit/8.png'),
        require('../src/images/projects/pentestkit/9.png'),
      ],
      references: [
        'https://play.google.com/store/apps/developer?id=The+Joloto+Project',
        'https://play.google.com/store/apps/details?id=com.pentestkit'
      ]
    },
  ],
}

export default data;