import React, { useState } from "react"
import reactLogo from '../images/stack/react.png'
import vueLogo from '../images/stack/vuejs.png'
import electronLogo from '../images/stack/electron.png'
import laravelLogo from '../images/stack/laravel.png'
import antdLogo from '../images/stack/antd.png'
import nodejsLogo from '../images/stack/nodejs.png'
import htmlLogo from '../images/stack/html.png'
import javascriptLogo from '../images/stack/javascript.png'
import cssLogo from '../images/stack/css.png'
import mysqlLogo from '../images/stack/mysql.png'
import mssqlLogo from '../images/stack/mssql.png'
import phpLogo from '../images/stack/php_kJuBcmu.png'
import paymongoLogo from '../images/stack/paymongo.png'
import netLogo from '../images/stack/net.png'
import bootstrapLogo from '../images/stack/bootstrap.png'
import metasploitLogo from '../images/stack/metasploit.png'
import reduxLogo from '../images/stack/redux.png'
import digitaloceanLogo from '../images/stack/digitalocean.png'
import awsLogo from '../images/stack/aws.png'
import materialuiLogo from '../images/stack/materialui.png'
import nativebaseLogo from '../images/stack/nativebase.png'
import mongodbLogo from '../images/stack/mongodb.png'
import dragonpayLogo from '../images/stack/dragonpay.png'
import aqwireLogo from '../images/stack/aqwire.png'
import nextLogo from '../images/stack/nextjs.png'
import tsLogo from '../images/stack/ts.png'
import expressLogo from '../images/stack/express.png'
import Ztext from 'react-ztext'
import { Progress, Tooltip, Radio } from 'antd';
const Skills = () => {
  const [active, setActive] = useState(1)
  const stackArray = [
    {
      label: 'React/Native',
      logo: reactLogo,
      percentage: 90,
      yearsOfExpe: 7,
      sortByExpe: true,
      fromTo: "2017 - Today",
      description: "Main stack (for Frontend) to create web and mobile application. ReactJS and ReactNative",
    },
    {
      label: 'Next Js',
      logo: nextLogo,
      percentage: 88,
      yearsOfExpe: 4,
      fromTo: "2020 - Today",
      sortByExpe: true,
      description: "Using NextJS Framework to be able to used extra features for reactJS"
    },
    {
      label: 'Vue Js',
      logo: vueLogo,
      percentage: 80,
      yearsOfExpe: 2,
      fromTo: "2022 - 2024",
      sortByExpe: true,
      description: "Used VueJS as (frontend) inside .NET Project"
    },
    {
      label: 'Node Js',
      logo: nodejsLogo,
      percentage: 89,
      yearsOfExpe: 7,
      fromTo: "2017 - Today",
      sortByExpe: true,
      description: "Using NodeJS for ReactJs / ReactNative and ExpressJs"
    },
    {
      label: 'Express Js',
      logo: expressLogo,
      percentage: 88,
      yearsOfExpe: 4,
      fromTo: "2020 - Today",
      sortByExpe: true,
      description: "Using ExpressJS Framework as my backend in my ReactJs(NextJs) / ReactNative Project"
    },
    {
      label: 'Electron Js',
      logo: electronLogo,
      percentage: 55,
      yearsOfExpe: 1,
      fromTo: "2020 - 2021",
      sortByExpe: false
    },
    {
      label: 'Redux',
      logo: reduxLogo,
      percentage: 90.0,
      yearsOfExpe: 7,
      fromTo: "2017 - Today",
      sortByExpe: true,
      description: "Been using Redux, Redux-Toolkit since I started using ReactJs.  "
    },

    {
      label: 'Javascript',
      logo: javascriptLogo,
      percentage: 90.0,
      yearsOfExpe: 7,
      fromTo: "2017 - Today",
      sortByExpe: true,
      description: "Using Javascript together with ReactJs / ReactNative and ExpressJs"
    },
    {
      label: 'Typescript',
      logo: tsLogo,
      percentage: 85.0,
      yearsOfExpe: 3,
      fromTo: "2021 - Today",
      sortByExpe: true,
      description: "Using Typescript in frontend (ReactJs, ReactNative) and backend (ExpressJs) for less errors and bugs in production. "
    },
    {
      label: 'Laravel',
      logo: laravelLogo,
      percentage: 80.0,
      yearsOfExpe: 3,
      fromTo: "2020 - 2023",
      sortByExpe: true,
      description: "Used Laravel as backend for my ReactJs/ReactNative Project"
    },
    {
      label: 'PHP',
      logo: phpLogo,
      percentage: 80.0,
      yearsOfExpe: 3,
      fromTo: "2020 - 2023",
      sortByExpe: true,
      description: "Used PHP for my backend in Laravel Projects."
    },
    {
      label: 'Ant Design',
      logo: antdLogo,
      percentage: 90.0,
      yearsOfExpe: 4,
      fromTo: "2020 - Today",
      sortByExpe: false
    },
    {
      label: 'Material UI',
      logo: materialuiLogo,
      percentage: 88.0,
      yearsOfExpe: 4,
      fromTo: "2020 - Today",
      sortByExpe: false
    },
    {
      label: 'Native Base',
      logo: nativebaseLogo,
      percentage: 88.0,
      yearsOfExpe: 4,
      fromTo: "2020 - Today",
      sortByExpe: false

    },
    {
      label: 'Bootstrap',
      logo: bootstrapLogo,
      yearsOfExpe: 7,
      fromTo: "2017 - Today",
      sortByExpe: false
    },
    {
      label: 'HTML',
      logo: htmlLogo,
      percentage: 86,
      yearsOfExpe: 7,
      fromTo: "2017 - Today",
      sortByExpe: true,
      description: "Using HTML/HTML5 together with ReactJs"
    },
    {
      label: 'CSS',
      logo: cssLogo,
      percentage: 86,
      yearsOfExpe: 7,
      fromTo: "2017 - Today",
      sortByExpe: true,
      description: "Using SASS/LESS/CSS together with ReactJs"
    },

    {
      label: 'MySQL',
      logo: mysqlLogo,
      percentage: 85,
      fromTo: "2020 - 2023",
      yearsOfExpe: 3,
      sortByExpe: true,
      description: "I used MySQL as database for ReactJs/Laravel"
    },
    {
      label: 'MsSQL',
      logo: mssqlLogo,
      percentage: 80,
      fromTo: "2022 - 2024",
      yearsOfExpe: 2,
      sortByExpe: true,
      description: "I used MSSQL as database for C# / .NET Project"
    },
    {
      label: 'Mongo DB',
      logo: mongodbLogo,
      percentage: 85,
      fromTo: "2020 - Today",
      yearsOfExpe: 4,
      sortByExpe: true,
      description: "Used MongoDB as database for my ReactJS/ExpressJS Project"
    },
    {
      label: 'C# & .net',
      logo: netLogo,
      percentage: 80.0,
      fromTo: "2022 - 2024",
      yearsOfExpe: 2,
      sortByExpe: true,
      description: "Used .Net as backend in my C# Project"
    },
    {
      label: 'Metasploit',
      logo: metasploitLogo,
      yearsOfExpe: 7,
      fromTo: "2017 - Today",
      sortByExpe: false
    },
    {
      label: 'Amazon AWS',
      logo: awsLogo,
      percentage: 87.0,
      yearsOfExpe: 4,
      fromTo: "2020 - Today",
      sortByExpe: true,
      description: "Execute AWS DevOps Tasks (DNS, S3, Route53, EC2, SSL, Servers, CI/ CD, Pipelines, Cloud Front) "
    },
    {
      label: 'Digital Ocean',
      logo: digitaloceanLogo,
      percentage: 65.0,
      yearsOfExpe: 1,
      fromTo: "2020 - 2021",
      sortByExpe: true
    },
    {
      label: 'Paymongo',
      logo: paymongoLogo,
      yearsOfExpe: 3,
      fromTo: "2020 - 2023",
      sortByExpe: false
    },
    {
      label: 'Dragonpay',
      logo: dragonpayLogo,
      yearsOfExpe: 3,
      fromTo: "2020 - 2023",
      sortByExpe: false
    },
    {
      label: 'AQwirePay',
      logo: aqwireLogo,
      yearsOfExpe: 3,
      fromTo: "2020 - 2023",
      sortByExpe: false
    },
  ]

  return (
    <div className="section" id="skills">
      <div className="container">
        <div className="skills-container">
          <h1>Skills</h1>
          <Radio.Group
            value={active}
            onChange={(e) => setActive(e.target.value)}
            options={[
              { value: 1, label: "By Stack" },
              { value: 2, label: "By Experience" },
            ]}
            style={{ color: 'white' }} />
          {
            active === 1 ? <div className="skills-grid">
              {
                stackArray.map((data, i) => {
                  return (
                    <div style={{ display: 'inline-block', textAlign: 'center', margin: 10 }} key={i}>
                      <Ztext
                        depth='1.5rem'
                        direction='forwards'
                        event='pointer'
                        eventRotation='30deg'
                        eventDirection='default'
                        layers={10}
                        perspective='600px'
                      >
                        <span role='img' aria-label='emoji'>
                          <img width="50" height="50" src={data.logo} alt={data.logo} />
                        </span>
                      </Ztext>
                      <h1>{data.label}</h1>
                    </div>

                  )
                })
              }
            </div> : null
          }
          {
            active === 2 ?
              <div className="skill-flex">
                {
                  stackArray
                    ?.filter(f => f.sortByExpe)
                    ?.sort((a, b) => b?.percentage - a?.percentage)
                    ?.map((data, i) => {
                      const text = data?.projects?.map((text, index) => {
                        return `${index + 1}. ${text} \n `
                      })
                      return (
                        <div className='item' key={i}>
                          <div className="icon">
                            <Ztext
                              depth='1.5rem'
                              direction='forwards'
                              event='pointer'
                              eventRotation='30deg'
                              eventDirection='default'
                              layers={10}
                              perspective='600px'
                              key={i}
                            >
                              <span role='img' aria-label='emoji'>
                                <img width="50" height="50" src={data.logo} alt={data.logo} />
                              </span>
                            </Ztext>
                            <h1>{data.label}</h1>
                          </div>
                          <div className="description">
                            <Tooltip placement="top" title={`Ratings: ${data?.percentage}/100%`}>
                              <Progress percent={data.percentage || 0}
                                // strokeColor={{
                                // '0%': '#108ee9',
                                // '100%': '#87d068',
                                // }}
                                style={{ width: '100%' }} />
                            </Tooltip>

                            <p><b>{data?.yearsOfExpe}</b> Years of experience </p>
                            <p>{data?.fromTo ? `(${data?.fromTo})` : ""}</p>
                            <p>{data?.description}</p>
                          </div>
                        </div>
                      )
                    })
                }
              </div>
              : null
          }



        </div>
      </div>
    </div >
  )
}

export default Skills
