import React, { useEffect, useState } from "react"
import Fade from "react-reveal/Fade"
import data from "../../yourdata"
import { Image, Button, Modal, Row, Col, Divider } from 'antd'
import ImageGallery from "react-image-gallery";


const WorkModal = (props) => {
    const [images, setImages] = useState([])
    // const images = [
    //     {
    //         original: "https://picsum.photos/id/1018/1000/600/",
    //         thumbnail: "https://picsum.photos/id/1018/250/150/",
    //     },
    //     {
    //         original: "https://picsum.photos/id/1015/1000/600/",
    //         thumbnail: "https://picsum.photos/id/1015/250/150/",
    //     },
    //     {
    //         original: "https://picsum.photos/id/1019/1000/600/",
    //         thumbnail: "https://picsum.photos/id/1019/250/150/",
    //     },
    // ];

    useEffect(() => {
        setImages([])
        data?.images?.map((image) => {
            setImages(x => {
                x = [...x, {
                    original: image,
                    thumbnail: image,
                }
                ]
                return x
            })
        })
    }, [props.data])
    const { data } = props

    return (
        <Modal width={1200} open={props.open} onCancel={() => props.onCancel()} footer={null} header={null} destroyOnClose contentBg={"#0A0F1D"}  >
            <Row>
                <Col lg={8} md={8} xs={24} style={{ padding: 12 }}>
                    <div className="work-items">
                        <div className="wrapper">
                            <div style={{ padding: 6 }}>
                                <h1>{data.title}</h1>
                                <div className="platform">Platform:&nbsp; {
                                    data.platform && data.platform.map((platform, index) => {
                                        return <div key={index}><span>{platform}</span>&nbsp;</div>
                                    })
                                }
                                </div>
                                <div className="role">Role:&nbsp; {
                                    data.role && data.role.map((role, index) => {
                                        return <div key={index}><span>{role}</span>&nbsp;</div>
                                    })
                                }
                                </div>
                            </div>
                        </div>
                        <div className="work-details">
                            <p>{data.description}</p>
                            <div className="tech">
                                {data.tech && data.tech.map((tech, index) => {
                                    return (
                                        <div className="tech-item" key={index}>
                                            <span>{tech}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <Divider />
                        <div className="references">
                            <h1>References</h1>
                            <p>WARNING: Please don't try to Log In, Play, or Test the website as it is running in production.</p>
                            {data?.references?.map((a, index) => {
                                return (
                                    <div className="item" key={index}>
                                        <a href={a} target="_blank"><u>{index + 1}. {a}</u></a>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Col>
                <Col lg={16} md={16} xs={24}>
                    <ImageGallery items={images} showFullscreenButton={true} autoPlay />
                </Col>
            </Row>
        </Modal>
    )
}

export default WorkModal
